import { NextPage } from 'next';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import HomeLayout from '../components/shared/HomeLayout';
import Layout from '../components/shared/Layout';
import { homePath } from '../utils/routes';

const NotFoundPage: NextPage = () => {
  return (
    <Layout>
      <HomeLayout>
        <main className="mx-auto flex w-full max-w-7xl flex-auto flex-col justify-center px-6 py-24 sm:py-48 lg:px-8">
          <p className="text-base font-semibold leading-8 text-light-blue">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">Page not found</h1>
          <p className="mt-6 text-base leading-7 text-gray-300">Sorry, we couldn’t find the page you’re looking for.</p>
          <div className="mt-10">
            <Link href={homePath}>
              <span className="text-sm font-semibold leading-7 text-light-blue cursor-pointer">
                <span aria-hidden="true">&larr;</span> Back to home
              </span>
            </Link>
          </div>
        </main>
      </HomeLayout>
    </Layout>
  );
};

export default NotFoundPage;
